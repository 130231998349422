import React from 'react'
import * as Styled from './Connect.style'
import fox from 'svg/metamask.svg'

const Connect = ({ accounts, balance, connectWallet }) =>
  <Styled.Div onClick={connectWallet} {...{ balance }}>
    <img src={fox} alt={'Metamask'} />
    {accounts ? <p className={'tooltip'}>{'Connected: '}<span>{accounts[0]}</span></p> : <p className={'underlineOnHover'}>{'Connect with Metamask'}</p>}
  </Styled.Div>

export default Connect
