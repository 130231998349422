import styled from 'styled-components'

export const Div = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  top: 2.5%;
  right: 20px;
  width: auto;
  font-weight: bold;
  background-color: #FFF;
  padding: 0px 10px;
  border-radius: 5px;
  text-align: center;
  img {
    width: 16px;
    margin-right: 4px;
  }
  > p {
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    position: relative;
    &.underlineOnHover {
      &:hover {
        text-decoration: underline;
      }
    }
    &:hover {
      &.tooltip {
        &:before {
          content: 'Balance: ${({ balance }) => balance.toString().substring(0,5)}';
          position: absolute;
          margin-top: 20px;
          margin-left: -20px;
          display: flex;
          background-color: #EEE;
          width: 100%;
          padding: 6px 8px;
          border-radius: 8px;
        }
      }
    }
    > span {
      display: inline-block;
      max-width: 90px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 2px;
    }
  }
`
