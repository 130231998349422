import horse from 'svg/21.svg'
import blockbots from 'img/blockbots.png'
import ck from 'img/cryptokitties.svg'
import ki from 'img/ki-logo.svg'
import * as Styled from './AppView.style'

const AppView = () => {
  const makeLink = (link, text) => <a href={link} target={'_blank'} rel="noreferrer">{text}</a>

  return (
      <Styled.Div>
        <Styled.Header>
          <img src={horse} alt={'Chained Horse #21'} />
          <p><a href={'https://etherscan.io/address/0xf7503bea549e73c0f260e42c088568fd865a358a'} target={'_blank'}>Chained Horse</a> #21</p>
          <h1>cryptosystems.online</h1>
        </Styled.Header>
        <Styled.Main>
          <p>Hello fren... we are a development studio focussed on blockchain games established in 2017.</p>
          <p>In September 2021 we worked with {makeLink('https://dapperlabs.com', 'DapperLabs')} and {makeLink('https://kittyhelper.co', 'KittyHelper')} on the {makeLink('https://cryptokitties.co/search', 'CryptoKitties v2 search engine')}.</p>
          <p>We love exploring innovative ways to use blockchain within gaming.</p>
          <Styled.Gallery>
            <div>
              <h2>{'Blockbots'}</h2>
              <img src={blockbots} alt={'Blockbots'} />
              <ul>
                <li><a href={'https://blockbots.online'}>{'www.blockbots.online'}</a></li>
              </ul>
              <p>(coming soon)</p>
            </div>
            <div>
              <h2>{'Kitty.International'}</h2>
              <img src={ki} alt={'Kitty.International'} />
              <ul>
                <li><a href={'https://kitty.international'}>{'www.kitty.international'}</a></li>
                <li><a href={'https://kitty.news'}>{'www.kitty.news'}</a></li>
                <li><a href={'https://kitty.family'}>{'www.kitty.family'}</a></li>
              </ul>
            </div>
            <div>
              <h2>{'CryptoKitties API'}</h2>
              <img src={ck} alt={'CryptoKitties'} />
              <ul>
                <li><a href={'https://cryptokitties.co'}>{'www.cryptokitties.co'}</a></li>
              </ul>
            </div>
          </Styled.Gallery>
        </Styled.Main>
      </Styled.Div>
  )
}

export default AppView
