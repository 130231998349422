/* eslint-disable no-undef*/
import { useState } from 'react'
import detectEthereumProvider from '@metamask/detect-provider'
import { fromWei } from 'web3-utils'
import AppView from './AppView'
import Connect from 'components/Connect'

function App() {
  const [accounts, setAccounts] = useState(false)
  const [balance, setBalance] = useState(false)
  const [userMessage, setUserMessage] = useState(false)

  const handleAccountsChanged = async (accounts) => {
    if (accounts.length === 0 || ethereum === undefined) {
      setAccounts(false)
      setUserMessage('Metamask not found. Please install the Metamask extension to use.')
      // MetaMask is locked or the user has not connected any accounts
    } else {
      ethereum.request({ method: 'eth_getBalance', params: [accounts[0]] })
        .then(async res => {
          setBalance(fromWei(res))
          setAccounts(accounts)
        })
        .catch(e => console.log(e))
      // User changes account in metamask:
      ethereum.on('accountsChanged', accounts => accounts.length > 0
        ? setAccounts(accounts)
        : setAccounts(false)
      )
      // User changes metamask chain. "Correctly handling chain changes can be complicated. We recommend reloading the page unless you have good reason not to."
      ethereum.on('chainChanged', (chainId) => window.location.reload())
      // User locks metamask (TODO: doesn't seem to work?)
      ethereum.on('disconnect', () => setAccounts(false))
    }
  }

  const init = async () => {
    const provider = await detectEthereumProvider()
    return provider
  }

  const initEth = () => {
    ethereum.request({ method: 'eth_requestAccounts' })
      .then(accounts => handleAccountsChanged(accounts))
      .catch(e => {
        setAccounts(false)
        setUserMessage('Metamask not found. Please install the Metamask extension to use.')
      })
  }

  return (
    <>
      <Connect {...{ accounts, balance }} connectWallet={() => init().then(response => window.ethereum ? initEth() : setUserMessage('Metamask not found. Please install the Metamask extension to use.'))} />
      {userMessage && <p>{userMessage}</p>}
      <AppView />
    </>
  )
}

export default App
