import styled from 'styled-components'
import bg1 from 'img/bg1.jpg'

export const Div = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 50px 5%;
  box-sizing: border-box;
  background-color: #bbe4ea;
  h2 {
    margin-bottom: 2rem;
  }
`

export const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  h1 {
    margin-bottom: 2rem;
  }
  img {
    width: 180px;
  }
  p {
    width: 200px;
    text-align: right;
    font-size: 11px;
    margin: -20px 0 2rem;
    font-style: italic;
  }
`

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > p {
    margin-bottom: 1.2rem;
    font-size: 12px;
    &:last-of-type {
      margin-bottom: 4rem;
    }
    @media (min-width: 600px) {
      margin-bottom: 1.4rem;
      font-size: 14px;
    }
  }
`

export const Gallery = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  @media (min-width: 1020px) {
    flex-direction: row;
    align-items: flex-start;
  }
  > div {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
    img {
      width: 180px;
      margin-bottom: 2rem;
    }
    ul {
      padding: 0;
      margin: 0;
      text-align: center;
      li {
        list-style-type: none;
      }
    }
  }
`
